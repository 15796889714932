<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <h3 class="ng-block-title fw-normal" v-if="user_id">
            {{ product?.name }}
          </h3>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li v-if="products.length">
              <div class="input-group">
                <div class="input-group-prepend d-none d-sm-flex">
                  <span
                    class="input-group-text bg-primary text-white font-weight-bold"
                  >
                    Product
                  </span>
                </div>
                <Multiselect
                  :options="products"
                  v-model="user_id"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  :searchable="true"
                  placeholder="Start typing..."
                  @change="fetchMargins"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>
      <div v-else>
        <div v-if="user_id" class="row">
          <div v-if="margins.margins.length > 0" class="row">
            <div class="mt-4 col-12">
              <div class="card card-bordered">
                <div class="card-content">
                  <table class="table pmd-table table-orders">
                    <thead class="tb-odr-head">
                      <tr class="tb-odr-item">
                        <th>Customer</th>
                        <th>Product</th>
                        <th class="text-right">
                          Weight (g)
                        </th>
                        <th class="text-right">
                          Pack
                        </th>
                        <th class="text-right">
                          Count
                        </th>
                        <th class="text-right">
                          Per Pack
                        </th>
                        <th class="text-right">
                          Per Box
                        </th>
                        <th class="text-right">
                          Per Kg
                        </th>
                        <th class="text-right">
                          £ Kg Price
                        </th>
                        <th class="text-right">
                          Cost Price
                        </th>
                        <th class="text-right">
                          Margin
                        </th>
                      </tr>
                    </thead>
                    <tbody class="tb-odr-body">
                      <tr
                        class="tb-odr-item"
                        v-for="(margin, key) in margins.margins"
                        :key="key"
                      >
                        <td>{{ margin.assignment?.user?.first_name }}</td>
                        <td>{{ margin.assignment?.name }}</td>
                        <td class="text-right">
                          {{ addCommas(margin.assignment?.weight) }}
                        </td>
                        <td class="text-right">
                          {{ margin.assignment?.primary_packaging?.name }}
                        </td>
                        <td class="text-right">
                          {{ addCommas(margin.assignment?.count) }}
                        </td>
                        <td class="text-right">
                          <span
                            v-if="
                              margin.assignment?.user?.contact?.currency ==
                                'euro'
                            "
                          >
                            €
                          </span>
                          <span
                            v-else-if="
                              margin.assignment?.user?.contact?.currency ==
                                'pound'
                            "
                          >
                            £
                          </span>
                          <span
                            v-else-if="
                              margin.assignment?.user?.contact?.currency ==
                                'dollar'
                            "
                          >
                            $
                          </span>
                          <span v-else>
                            KSh
                          </span>
                          {{ addCommas(margin.per_pack) }}
                        </td>
                        <td class="text-right">
                          <span
                            v-if="
                              margin.assignment?.user?.contact?.currency ==
                                'euro'
                            "
                          >
                            €
                          </span>
                          <span
                            v-else-if="
                              margin.assignment?.user?.contact?.currency ==
                                'pound'
                            "
                          >
                            £
                          </span>
                          <span
                            v-else-if="
                              margin.assignment?.user?.contact?.currency ==
                                'dollar'
                            "
                          >
                            $
                          </span>
                          <span v-else>
                            KSh
                          </span>
                          {{ addCommas(margin.per_box) }}
                        </td>
                        <td class="text-right">
                          <span
                            v-if="
                              margin.assignment?.user?.contact?.currency ==
                                'euro'
                            "
                          >
                            €
                          </span>
                          <span
                            v-else-if="
                              margin.assignment?.user?.contact?.currency ==
                                'pound'
                            "
                          >
                            £
                          </span>
                          <span
                            v-else-if="
                              margin.assignment?.user?.contact?.currency ==
                                'dollar'
                            "
                          >
                            $
                          </span>
                          <span v-else>
                            KSh
                          </span>
                          {{ addCommas(margin.per_kg) }}
                        </td>
                        <td class="text-right">
                          £ {{ addCommas(margin.pound_per_kg) }}
                        </td>
                        <td class="text-right">
                          £ {{ addCommas(margin.cost_price) }}
                        </td>
                        <td class="text-right">{{ margin.margin }}%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="alert alert-danger text-center" v-else>
            <h5>
              <em class="icon ni ni-alert text-danger"></em>
            </h5>
            Product has no margins.
          </div>
        </div>

        <div class="alert alert-warning text-center" v-else>
          <h5>
            <em class="icon ni ni-alert text-warning"></em>
          </h5>
          Please select a customer first.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import NgongVegBanner from "@/components/NgongVegBanner";
import { computed, defineComponent, onMounted, ref } from "vue";
import http from "../../../../../common/services/http";
export default defineComponent({
  name: "Margins",
  components: { NgongVegBanner },
  setup() {
    const processing = ref(false);

    const products = ref([]);
    const user_id = ref(0);
    const product = computed(() => {
      return products.value?.find((c: any) => c.id == user_id.value);
    });

    const margins = ref({});

    function fetchCustomers() {
      processing.value = true;
      http.get(`/api/products?fetch=all`).then(res => {
        products.value = res.data;
        processing.value = false;
      });
    }

    function fetchMargins(id: any) {
      processing.value = true;

      http.get(`/api/margins-by-product?product=${id}`).then(res => {
        console.log(res.data);
        margins.value = res.data;
        processing.value = false;
      });
    }

    function addCommas(value: number) {
      if (value !== undefined) {
        const value2dp = value.toFixed(2);
        return value2dp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

    onMounted(() => {
      fetchCustomers();
    });

    return {
      user_id,
      product,
      products,
      fetchMargins,
      margins,
      processing,
      addCommas
    };
  }
});
</script>

<style scoped></style>
