
import NgongVegBanner from "@/components/NgongVegBanner";
import { computed, defineComponent, onMounted, ref } from "vue";
import http from "../../../../../common/services/http";
export default defineComponent({
  name: "Margins",
  components: { NgongVegBanner },
  setup() {
    const processing = ref(false);

    const products = ref([]);
    const user_id = ref(0);
    const product = computed(() => {
      return products.value?.find((c: any) => c.id == user_id.value);
    });

    const margins = ref({});

    function fetchCustomers() {
      processing.value = true;
      http.get(`/api/products?fetch=all`).then(res => {
        products.value = res.data;
        processing.value = false;
      });
    }

    function fetchMargins(id: any) {
      processing.value = true;

      http.get(`/api/margins-by-product?product=${id}`).then(res => {
        console.log(res.data);
        margins.value = res.data;
        processing.value = false;
      });
    }

    function addCommas(value: number) {
      if (value !== undefined) {
        const value2dp = value.toFixed(2);
        return value2dp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }

    onMounted(() => {
      fetchCustomers();
    });

    return {
      user_id,
      product,
      products,
      fetchMargins,
      margins,
      processing,
      addCommas
    };
  }
});
